import { pickBy } from 'lodash'
import mock from '@/views/apps/buy-sell/config/mock'

export function toFlatObject(obj, concatenator = '.') {
  return Object.keys(obj || {}).reduce((acc, key) => {
    if (typeof obj[key] !== 'object') {
      return {
        ...acc,
        [key]: obj[key],
      }
    }

    const flattenedChild = toFlatObject(obj[key], concatenator)

    return {
      ...acc,
      ...Object.keys(flattenedChild).reduce(
        (childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey],
        }),
        {},
      ),
    }
  }, {})
}

export const cleanObj = obj => pickBy(obj, value => ![null, undefined, ''].includes(value))

export function objectToFormData(obj) {
  const formData = new FormData()
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      formData.append(key, obj[key])
    }
  }
  return formData
}

export function downloadFile(file, name = 'file') {
  const href = URL.createObjectURL(file)

  // create "a" HTML element with href to file & click
  const link = document.createElement('a')
  link.hidden = true
  link.href = href
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}

const mockData = {
  name: 'Mock request',
  request: { method: 'GET', url: '/test-url' },
  mock: {
    items: [
      {
        id: 1,
        text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        date: new Date(),
      },
      {
        id: 2,
        text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        date: new Date(),
      },
      {
        id: 3,
        text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        date: new Date(),
      },
    ],
    page: 1,
    total: 4113,
  },
}
export function mockApi(data = mockData, delay = 1000) {
  return new Promise(resolve => {
    setTimeout(() => {
      console.group(data.name || mockData.name)
      console.table(data.request || mockData.request)
      console.groupEnd()
      resolve({
        data: data?.mock || mockData.mock,
      })
    }, delay)
  })
}
