import axios from '@/plugins/axios'

const state = () => ({
  countries: [],
})

const getters = {
  countries: store => store.countries,
}

const actions = {
  fetchCountries({ commit }) {
    return axios({ method: 'GET', url: '/countries' }).then(response => {
      commit('setCountries', { countries: response.data })

      return response?.data
    })
  },
}

const mutations = {
  setCountries(state, { countries } = {}) {
    state.countries = countries || []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
