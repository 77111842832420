import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  paymentMethod: {},
  paymentMethods: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  paymentMethod: store => store.paymentMethod,
  paymentMethods: store => store.paymentMethods,
}

const actions = {
  fetchPaymentMethods(
    { commit },
    { limit = 30, page = 1, isActive = undefined, search = undefined, fiatId = undefined } = {},
  ) {
    const requestQuery = {
      limit,
      page,
      search,
      isActive,
      fiatId,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/payment-methods', params }).then(response => {
      commit('setPaymentMethods', { paymentMethods: response.data, params })

      return response
    })
  },

  createPaymentMethod(store, form) {
    return axios({ method: 'POST', url: `/payment-methods`, data: form }).then(response => {
      return response
    })
  },

  fetchPaymentMethodById({ commit }, id) {
    return axios({ method: 'GET', url: `/payment-methods/${id}` }).then(response => {
      commit('setPaymentMethod', { paymentMethod: response.data })

      return response
    })
  },

  updatePaymentMethodById({ commit }, { id, form }) {
    return axios({ method: 'PUT', url: `/payment-methods/${id}`, data: form }).then(response => {
      return response
    })
  },

  deletePaymentMethodById({ commit }, id) {
    return axios({ method: 'DELETE', url: `/payment-methods/${id}` }).then(response => {
      return response
    })
  },

  createPaymentMethodFee(store, form) {
    return axios({ method: 'POST', url: `/payment-methods/fee`, data: form }).then(response => {
      return response
    })
  },
  
  updatePaymentMethodFee(store, form) {
    return axios({ method: 'PATCH', url: `/payment-methods/fee`, data: form }).then(response => {
      return response
    })
  }
}

const mutations = {
  setPaymentMethods(state, { paymentMethods, params } = {}) {
    const { limit, page } = params

    state.paymentMethods.value = paymentMethods.items
    state.paymentMethods.count = paymentMethods.total
    state.paymentMethods.limit = limit
    state.paymentMethods.page = page
  },

  setPaymentMethod(state, { paymentMethod } = {}) {
    state.paymentMethod = paymentMethod
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
