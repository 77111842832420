export default [
  // roles
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/apps/roles/RolesView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'users-roles-page',
      pageTitle: 'Roles',
      breadcrumb: [
        {
          text: 'Roles',
        },
      ],
    },
  },
  {
    path: '/roles/create',
    component: () => import('@/views/apps/roles/RolesCreate.vue'),
    name: 'roles-create',
    meta: {
      requiredAuth: true,
      permission: 'users-roles-page',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
          to: '/roles',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    name: 'user-edit',
    path: '/roles/edit/:id',
    component: () => import('@/views/apps/roles/RolesCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'users-roles-page',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
          to: '/roles',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
