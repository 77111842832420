import axios from '@/plugins/axios'

const state = () => ({
  fee: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  fee: store => store.fee,
}

const actions = {
  fetchFee({ commit }) {
    return axios({ method: 'GET', url: '/pg-fees/list' }).then(response => {
      commit('setFee', { fee: response?.data })

      return response
    })
  },

  fetchFeeById(store, id) {
    return axios({ method: 'GET', url: `/pg-fees`, params: { id } })
  },

  updateFee(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/pg-fees`, data: { ...form, id } })
  },

  fetchThreshold(store) {
    return axios({ method: 'GET', url: '/pg-fees/threshold' })
  },
  updateThreshold(store, { form } = {}) {
    return axios({ method: 'PUT', url: '/pg-fees/threshold', data: form })
  },
}

const mutations = {
  setFee(state, { fee } = {}) {
    state.fee.value = fee.data || []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
