import axios from '@/plugins/axios'

const state = () => ({
  newItems: 0,
})

const getters = {
  newItems: state => state.newItems,
}

const actions = {
  fetchDashboardInfo() {
    return axios({ method: 'GET', url: '/kyc/dashboard' }).then(response => response)
  },
  fetchTable(ctx, params) {
    return axios({ method: 'GET', url: '/kyc', params }).then(response => response)
  },
  fetchInfo(ctx, id) {
    return axios({ method: 'GET', url: `/kyc/${id}` }).then(response => response)
  },
  fetchInfoDetail(ctx, id) {
    return axios({ method: 'GET', url: `/kyc/${id}/full-info` }).then(response => response)
  },
  setResponsible(ctx, data) {
    return axios({ method: 'POST', url: '/kyc/set-responsible', data })
  },
  setActions(ctx, { data, url }) {
    console.log(data, url)
    return axios({ method: 'POST', url, data })
  },
  getLink(ctx, data) {
    return axios({ method: 'POST', url: '/kyc/link', data })
  },
  setApplicantInformation(ctx, data) {
    return axios({ method: 'POST', url: '/kyc/applicant-information', data })
  },
  // updateInfo(store, form) {
  //   return axios({ method: 'POST', url: '/kyc/dashboard', data: form })
  // },
}

const mutations = {
  setNewItems(state, payload) {
    state.newItems = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
