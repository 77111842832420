import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  deal: {},
  dealInfo: {},
  deals: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  responsibles: [],
  dealsStatistic: [],
  dealUpdate: null,
});

const mutations = {
  setDeal(state, deal) {
    state.deal = deal
  },
  setDeals(state, { deals, params } = {}) {
    const { limit, page } = params

    state.deals.value = deals.items
    state.deals.count = deals.total
    state.deals.limit = limit
    state.deals.page = page
  },
  setResponsibles(state, { responsibles } = {}) {
    state.responsibles = responsibles || []
  },
  setDealsStatistic(state, payload) {
    state.dealsStatistic = payload
  },
  setDealUpdate(state, v) {
    state.dealUpdate = v
  },
};

const actions = {
  fetchListDeals(
    { commit },
    { limit = 10, page = 1, search = undefined, status = undefined, dateFrom = undefined, dateTo = undefined, responsibleId = undefined, orderId = undefined, sortOrder = undefined } = {},
  ) {
    const requestQuery = {
      page,
      limit,
      search,
      dateFrom,
      dateTo,
      status,
      responsibleId,
      orderId,
      sortOrder,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/deals', params }).then(response => {
      commit('setDeals', { deals: response.data, params })

      return response?.data
    })
  },
  fetchDealById({ commit }, id) {
    return axios({ method: 'GET', url: `/deals/${id}` }).then(response => {
      commit('setDeal', response.data)

      return response
    })
  },
  fetchResponsibles({ commit }) {
    return axios({ method: 'GET', url: '/admin-user' }).then(response => {
      commit('setResponsibles', { responsibles: response.data.items })

      return response?.data
    })
  },
  addModerator(store, dealId) {
    console.log('store dealId', dealId)
    return axios({ method: 'PATCH', url: '/deals/add-moderator', data: dealId })
  },
  sendAdminMessage(store, form) {
    return axios({ method: 'POST', url: '/deals/send-message', data: form }).then(response => {
      return response?.data
    })
  },
  returnMoney(store, dealId) {
    return axios({ method: 'POST', url: '/deals/return-money', data: dealId }).then(response => {
      return response?.data
    })
  },
  sendMoney(store, dealId) {
    return axios({ method: 'POST', url: '/deals/send-money', data: dealId }).then(response => {
      return response?.data
    })
  },

  fetchDealsStatistic({ commit }) {
    return axios({ method: 'GET', url: '/deals/statistic' }).then(response => {

      // const { total, completed, inProgress, canceled } = response.data.reduce((acc, { status, dealsCount }) => {
      //   const count = parseInt(dealsCount);
      //   acc.total += count;
      //   acc.completed += ["deal_completed"].includes(status) ? count : 0;
      //   acc.inProgress += ["deal_open", "dispute_open", "money_sent"].includes(status) ? count : 0;
      //   acc.canceled += ["deal_canceled", "time_over"].includes(status) ? count : 0;
      //   return acc;
      // }, { total: 0, completed: 0, inProgress: 0, canceled: 0 });

      // const result = {
      //   total,
      //   completed,
      //   inProgress,
      //   canceled,
      // }
      const fin = response.data.reduce((result, { status, dealsCount }) => {
        result[status] = parseInt(dealsCount)
        return result
      }, {})

      commit('setDealsStatistic', fin)
      return response?.data
    })
  },
};

const getters = {
  deal: store => store.deal,
  deals: store => store.deals,
  responsibles: store => store.responsibles,
  dealsStatistic: store => store.dealsStatistic,
  dealUpdate: store => store.dealUpdate,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};