import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  faq: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  faq: store => store.faq,
}

const actions = {
  fetchFAQ({ commit }, { limit = 30, page = 1, search = undefined } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/faq', params }).then(response => {
      commit('setFAQ', { faq: response?.data, params })

      return response
    })
  },

  fetchQuestionById(store, id) {
    return axios({ method: 'GET', url: `/faq/${id}` }).then(response => response)
  },

  createQuestion(store, form) {
    return axios({
      method: 'POST',
      url: '/faq',
      data: form,
    })
  },

  updateQuestion(store, { id, form }) {
    return axios({
      method: 'PUT',
      url: `/faq/${id}`,
      data: form,
    })
  },

  removeQuestion({ commit }, id) {
    return axios({ method: 'DELETE', url: `/faq/${id}` }).then(response => {
      commit('removeQuestion', id)

      return response
    })
  },
}

const mutations = {
  setFAQ(state, { faq, params } = {}) {
    const { limit, page } = params

    state.faq.value = faq.items || []
    state.faq.count = faq.total || 0
    state.faq.limit = limit
    state.faq.page = page
  },

  removeQuestion(state, id) {
    state.faq.value = state.faq.value?.filter(question => question.id !== id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
