export default [
  // transaction-monitor
  {
    path: '/payment-methods',
    name: 'payment-methods',
    component: () => import('@/views/apps/payment-methods/PaymentMethodsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Payment Methods',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },

  {
    path: '/payment-methods/create',
    name: 'payment-methods-create',
    component: () => import('@/views/apps/payment-methods/PaymentMethodsCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      breadcrumb: [
        {
          text: 'Payment Methods',
          active: true,
          to: '/payment-methods',
        },
        {
          text: 'Create',
        },
      ],
    },
  },

  {
    path: '/payment-methods/:id',
    name: 'payment-methods-edit',
    component: () => import('@/views/apps/payment-methods/PaymentMethodsCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      breadcrumb: [
        {
          text: 'Payment Methods',
          active: true,
          to: '/payment-methods',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },

  {
    path: '/payment-methods/fee/create/:paymentMethodId',
    name: 'payment-methods-fee-create',
    component: () => import('@/views/apps/payment-methods/PaymentMethodsFeeCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      // breadcrumb: // add custom breadcrumb on page
    },
  },

  {
    path: '/payment-methods/:id/fee/:feeId',
    name: 'payment-methods-fee-edit',
    component: () => import('@/views/apps/payment-methods/PaymentMethodsFeeCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      // breadcrumb: // add custom breadcrumb on page
    },
  },
]
