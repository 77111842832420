export default [
  // wallet-transactions
  {
    path: '/wallet-transactions',
    name: 'wallet-transactions',
    component: () => import('@/views/apps/wallet-transactions/WalletTransactionsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'wallets-page',
      pageTitle: 'Wallet transactions',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
