export default [
  {
    path: '/threshold',
    name: 'threshold',
    component: () => import('@/views/apps/threshold/ThresholdTransaction.vue'),
    meta: {
      requiredAuth: true,
      permission: 'buy-sell-page',
      pageTitle: 'Threshold',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
