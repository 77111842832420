export default [
  // wallet-defi
  {
    path: '/wallet-defi',
    name: 'wallet-defi',
    component: () => import('@/views/apps/wallet-defi/WalletDeFi.vue'),
    meta: {
      requiredAuth: true,
      permission: 'wallets-page',
      pageTitle: 'Wallet DeFi',
      breadcrumb: [
        {
          text: 'Wallet DeFi',
        },
      ],
    },
  },
]
