import axios from '@/plugins/axios'

const state = () => ({
  coldWallet: {
    value: [],
  },
  coldWalletBuySell: {
    value: [],
  },
})

const getters = {
  coldWallet: store => store.coldWallet,
  coldWalletBuySell: store => store.coldWalletBuySell,
}

const actions = {
  fetchColdWallet({ commit }) {
    return axios({ method: 'GET', url: '/cold-wallets' }).then(response => {
      commit('setColdWallet', { coldWallet: response.data })

      return response?.data
    })
  },
  fetchColdWalletBuySell({ commit }) {
    return axios({ method: 'GET', url: '/cold-wallets/pg-with-balances' }).then(response => {
      commit('setColdWalletBuySell', { coldWalletBuySell: response.data })

      return response?.data
    })
  },

  fetchColdWalletsWithBalance({ commit }) {
    return axios({ method: 'GET', url: '/cold-wallets/with-balances' }).then(response => {
      commit('setColdWallet', { coldWallet: response.data })

      return response?.data
    })
  },
}

const mutations = {
  setColdWallet(state, { coldWallet } = {}) {
    state.coldWallet.value = coldWallet || []
  },

  setColdWalletBuySell(state, { coldWalletBuySell } = {}) {
    state.coldWalletBuySell.value = coldWalletBuySell || []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
