import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import axios from '@/plugins/axios'

const corectAuthenticated = () => {
  const locStExpires = localStorage.getItem('expires_at') * 1000
  const dateNow = new Date().getTime()
  if (locStExpires) {
    return dateNow < locStExpires
  }

  return false
}
const getAdmin = () => JSON.parse(window.localStorage.getItem('admin')) || null

const state = () => ({
  token: null,
  admin: getAdmin(),
  isAuth: corectAuthenticated(),
})

const getters = {
  token: state => state.token,
  admin: state => state.admin,
  isAuth: state => state.isAuth,
}

const mutations = {
  setToken(state, { token, rememberMe = localStorage.getItem('rememberMe') }) {
    state.token = token

    Vue.$cookies.set('token', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`

    const parsToken = jwtDecode(token)
    localStorage.setItem('is_expanded', 'true')
    localStorage.setItem('token', token)
    localStorage.setItem('expires_at', parsToken.exp)
    localStorage.setItem('rememberMe', rememberMe)
  },

  clearToken(state) {
    state.token = null
    Vue.$cookies.remove('token')

    localStorage.removeItem('token')
    localStorage.removeItem('is_expanded')
    localStorage.removeItem('adminId')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('rememberMe')
    localStorage.removeItem('admin')
    localStorage.removeItem('roles')
    localStorage.removeItem('permissions')
  },

  setAuth: (state, isAuth) => {
    state.isAuth = isAuth
  },

  setProfile(state, profile) {
    state.admin = profile
    localStorage.setItem('admin', JSON.stringify(profile))
  },
}

const actions = {
  login({ commit }, params) {
    const data = { email: params.email, password: params.password }

    return axios({ method: 'POST', url: '/auth/login', data }).then(response => {
      commit('setToken', { token: response.data?.token, rememberMe: params.rememberMe })

      return response
    })
  },

  loginWithTwofa({ commit }, params) {
    const data = { code: params.code }

    return axios({ method: 'POST', url: '/2fa/authenticate', data }).then(response => {
      commit('setToken', { token: response.data?.token, rememberMe: params.rememberMe })

      return response
    })
  },

  logout({ commit }) {
    commit('clearToken')
    commit('setAuth', false)
  },

  getRefreshToken({ commit }) {
    return axios({ method: 'GET', url: '/auth/token' })
      .then(response => {
        commit('setToken', { token: response.data?.token, rememberMe: localStorage.getItem('rememberMe') })

        return response.data?.token
      })
      .catch(error => {
        console.error('Refresh token!', error)
        return null
      })
  },

  fetchProfile({ commit }) {
    return axios({ method: 'GET', url: '/profile' }).then(response => {
      commit('setProfile', response.data)

      return response
    })
  },

  generateTwofaCode({ commit }, params) {
    return axios({ method: 'POST', url: '/2fa/generate', data: params })
  },

  turnOnTwofa({ commit }, params) {
    return axios({ method: 'POST', url: '/2fa/on', data: params })
  },
  turnOffTwofa({ commit }, params) {
    return axios({ method: 'POST', url: '/2fa/off', data: params })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
