import Vue from 'vue'
import Vuex from 'vuex'

import app from './app-config/app'
import appConfig from './app-config/app-config'
import verticalMenu from './app-config/vertical-menu'
import modules from './module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { app, appConfig, verticalMenu, ...modules },
  strict: process.env.DEV,
})
