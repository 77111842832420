export default [
  // transaction-monitor
  {
    path: '/hold-settings',
    name: 'hold-settings',
    component: () => import('@/views/apps/hold-settings/HoldSettingsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Hold Settings',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
  {
    path: '/hold-settings/level-edit/:id',
    name: 'hold-settings-level-edit',
    component: () => import('@/views/apps/hold-settings/LevelEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Level Edit',
      breadcrumb: [
        {
          text: 'Edit',
        },
      ],
    },
  },
]
