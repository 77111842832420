export const transactionBuySellTypes = {
  send_crypto: 'send_crypto',
  receive_crypto: 'receive_crypto',
}

export const transactionBuySellStatus = {
  success: 'success',
  pending: 'pending',
  pending_payout: 'pending_payout',
  reject: 'reject',
  error: 'error',
  new: 'new',
  paid: 'paid',
  wait_fiat_payment: 'wait_fiat_payment',
  wait_crypto_payment: 'wait_crypto_payment',
  crypto_is_sending: 'crypto_is_sending',
}
