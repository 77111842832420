export default [
  // users
  {
    path: '/users-invites',
    name: 'users-invites',
    component: () => import('@/views/apps/users-invites/UsersInvitesView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'user-invites-page',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Invites',
        },
      ],
    },
  },
]
