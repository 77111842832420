const state = () => ({});

const actions = {};

const getters = {};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};