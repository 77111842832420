export default [
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/apps/team/TeamView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'teams-page',
      pageTitle: 'Team',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
  {
    path: '/team/create',
    name: 'team-create',
    component: () => import('@/views/apps/team/TeamCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'teams-page',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
          to: '/team',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    path: '/team/edit/:id',
    name: 'team-edit',
    component: () => import('@/views/apps/team/TeamCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'teams-page',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
          to: '/team',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
