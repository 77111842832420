export default [
  {
    path: '/',
    name: 'users-statistic-page',
    component: () => import('@/views/apps/analytics-dashboard/AnalyticsDashboard.vue'),
    meta: {
      requiredAuth: true,
      permission: 'users-statistic-page',
      pageTitle: 'Analytics Dashboard',
      breadcrumb: [
        {
          text: 'Analytics Dashboard',
        },
      ],
    },
  },
]
