import axios from '@/plugins/axios'

const state = () => ({})

const actions = {
  updateBalance(store, data) {
    return axios({ method: 'POST', url: '/tasks/update-balance', data })
  },
}

const getters = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
