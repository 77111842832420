import axios from '@/plugins/axios'

const state = () => ({
  roles: [],
})

const getters = {
  roles: store => store.roles,
}

const actions = {
  fetchRoles({ commit }) {
    return axios({ method: 'GET', url: '/roles' }).then(res => {
      commit('setRoles', res?.data)

      return res?.data
    })
  },

  fetchRoleById(store, id) {
    return axios({ method: 'GET', url: `/roles/${id}` })
  },

  removeRole({ commit }, id) {
    return axios({ method: 'DELETE', url: `/roles/${id}` }).then(response => {
      commit('removeRole', id)

      return response
    })
  },

  createRole(store, form) {
    return axios({ method: 'POST', url: '/roles', data: form })
  },

  updateRole(store, { id, form }) {
    return axios({ method: 'PUT', url: `/roles/${id}`, data: form })
  },

  fetchPermissions() {
    return axios({ method: 'GET', url: '/roles/permissions' }).then(response => response.data)
  },
}

const mutations = {
  setRoles(state, roles) {
    state.roles = roles
  },
  removeRole(state, id) {
    state.roles = state.roles.filter(role => role.id !== id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
