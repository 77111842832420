export default [
  // transaction-monitor
  {
    path: '/cold-wallet-buy-sell',
    name: 'cold-wallet-buy-sell',
    component: () => import('@/views/apps/cold-wallet-buy-sell/HotWalletsBuySellWalletView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'cold-wallets-page',
      pageTitle: 'Hot wallet buy/sell',
      breadcrumb: [
        {
          text: 'Hot wallet',
        },
      ],
    },
  },
]
