export default [
  // users
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/apps/users/UsersView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'users-user-page',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
    },
  },
  {
    path: '/users/create',
    component: () => import('@/views/apps/users/UsersCreate.vue'),
    name: 'users-create',
    meta: {
      requiredAuth: true,
      permission: 'users-user-page',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
          to: '/users',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    path: '/user/:id',
    component: () => import('@/views/apps/users/UserView.vue'),
    name: 'users-view',
    meta: {
      requiredAuth: true,
      permission: 'users-user-page',
    },
  },
  // {
  //   path: '/users/:id',
  //   component: () => import('@/views/apps/users/UsersCreate.vue'),
  //   name: 'users-edit',
  //   meta: {
  //     requiredAuth: true,
  //     permission: 'users-user-page',
  //     breadcrumb: [
  //       {
  //         text: 'Users',
  //         active: true,
  //         to: '/users',
  //       },
  //       {
  //         text: 'Edit',
  //       },
  //     ],
  //   },
]
