export default [
  // create admin
  {
    path: '/create-admin',
    name: 'create-admin',
    component: () => import('@/views/apps/create-admin/CreateAdminView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'admin-user-page',
      pageTitle: 'Create Admin',
      breadcrumb: [
        {
          text: 'Users',
        },
      ],
    },
  },
  {
    path: '/create-admin/create',
    component: () => import('@/views/apps/create-admin/CreateAdminCreate.vue'),
    name: 'create-admin-create',
    meta: {
      requiredAuth: true,
      permission: 'admin-user-page',
      breadcrumb: [
        {
          text: 'Create Admin',
          active: true,
          to: '/create-admin',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    name: 'create-admin-edit',
    path: '/create-admin/edit/:id',
    component: () => import('@/views/apps/create-admin/CreateAdminEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'admin-user-page',
      breadcrumb: [
        {
          text: 'Create Admin',
          active: true,
          to: '/create-admin',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
