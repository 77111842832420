export default [
  {
    path: '/hold-leaderboard-winners',
    name: 'hold-leaderboard-winners',
    component: () => import('@/views/apps/hold-leaderboard-winners/HoldLeaderboard.vue'),
    meta: {
      requiredAuth: true,
      permission: 'finance-payment-methods-page',
      pageTitle: 'Hold Leaderboard Winners',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
