import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  fiatCurrency: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  fiatCurrency: store => store.fiatCurrency,
}

const actions = {
  fetchFiatCurrency({ commit }, { limit = 30, page = 1, search = undefined } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/currency', params }).then(response => {
      commit('setCurrency', { fiatCurrency: response.data, params })

      return response?.data
    })
  },

  fetchFiatCurrencyById(store, id) {
    return axios({ method: 'GET', url: `/currency/${id}` })
  },

  updateFiatCurrency(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/currency/${id}`, data: form })
  },

  fetchSearchedFiatCurrency({ dispatch }, search) {
    return dispatch('fetchFiatCurrency', { search })
  },
}

const mutations = {
  setCurrency(state, { fiatCurrency, params } = {}) {
    const { limit, page } = params

    state.fiatCurrency.value = fiatCurrency.items || []
    state.fiatCurrency.count = fiatCurrency.total || 0
    state.fiatCurrency.limit = limit
    state.fiatCurrency.page = page
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
