import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  adminUsers: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  adminUsers: store => store.adminUsers,
}

const actions = {
  fetchAdminUsers({ commit }, { limit = 10, page = 1, search = undefined } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/admin-user', params }).then(response => {
      commit('setAdminUsers', { users: response.data, params })

      return response?.data
    })
  },

  fetchAdminUserById(store, id) {
    return axios({ method: 'GET', url: `/admin-user/${id}` })
  },

  createAdminUser(store, form) {
    return axios({ method: 'POST', url: `/admin-user`, data: form })
  },

  updateAdminUser(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/admin-user/${id}`, data: form })
  },

  updateAdminUserPassword(store, { id, password }) {
    return axios({ method: 'PATCH', url: `/admin-user/change-password/${id}`, data: { password } })
  },

  updateUserStatus({ commit }, { id, status }) {
    return axios({ method: 'POST', url: `/admin-user/${status}/${id}` }).then(response => {
      commit('updateUserItemInList', response.data)

      return response
    })
  },
}

const mutations = {
  setAdminUsers(state, { users, params } = {}) {
    const { limit, page } = params

    state.adminUsers.value = users.items || []
    state.adminUsers.count = users.total || 0
    state.adminUsers.limit = limit
    state.adminUsers.page = page
  },

  updateUserItemInList(state, user) {
    const listDataIndex = state.adminUsers?.value?.findIndex(item => item?.id === user.id)

    if (listDataIndex === -1) return

    state.adminUsers.value[listDataIndex] = { ...user }
    state.adminUsers.value.splice(state.adminUsers.value?.length)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
