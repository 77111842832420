export default [
  // transaction-monitor
  {
    path: '/p2p-orders',
    name: 'p2p-orders',
    component: () => import('@/views/apps/p2p-orders/P2pOrdersView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'orders-page',
      pageTitle: 'P2P',
      breadcrumb: [
        {
          text: 'Orders',
        },
      ],
    },
  },
]
