export default [
  // transaction-monitor
  {
    path: '/p2p-deals',
    name: 'p2p-deals',
    component: () => import('@/views/apps/p2p-deals/P2pDealsView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'market-deals-page',
      pageTitle: 'P2P',
      breadcrumb: [
        {
          text: 'Deals',
        },
      ],
    },
  },
  {
    path: '/p2p-deal/info/:id',
    component: () => import('@/views/apps/p2p-deals/P2pDealsInfo.vue'),
    name: 'p2p-deals-info',
    meta: {
      requiredAuth: true,
      permission: 'market-deals-page',
      breadcrumb: [
        {
          text: 'Deals',
          active: true,
          to: '/p2p-deals',
        },
        {
          text: 'Deal info',
        },
      ],
    },
  },
  {
    path: '/p2p-deal/chat/:id',
    component: () => import('@/views/apps/p2p-deals/P2pDealsChat.vue'),
    name: 'p2p-deals-chat',
    meta: {
      requiredAuth: true,
      permission: 'market-deals-page',
      breadcrumb: [
        {
          text: 'Deals',
          active: true,
          to: '/p2p-deals',
        },
        {
          text: 'Deal chat',
        },
      ],
    },
  },
]
