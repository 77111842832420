export default [
  // transaction-monitor
  {
    path: '/crypto-currency',
    name: 'crypto-currency',
    component: () => import('@/views/apps/currency-crypto/CurrencyCryptoView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'crypto-currency-page',
      pageTitle: 'Crypto Currency',
      breadcrumb: [
        {
          text: 'Crypto Currency',
        },
      ],
    },
  },

  {
    path: '/crypto-currency/edit/:id',
    name: 'crypto-currency-edit',
    component: () => import('@/views/apps/currency-crypto/CurrencyCryptoEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'crypto-currency-page',
      pageTitle: 'Crypto Currency',
      breadcrumb: [
        {
          text: 'Edit',
        },
      ],
    },
  },
]
