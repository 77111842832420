import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  orders: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
});

const mutations = {
  seOrders(state, { orders, params } = {}) {
    const { limit, page } = params

    state.orders.value = orders.items
    state.orders.count = orders.total
    state.orders.limit = limit
    state.orders.page = page
  },
};

const actions = {
  fetchOrdersList(
    { commit },
    { limit = 10, page = 1, search = undefined, status = undefined, cryptoId = undefined, fiatId = undefined, direction = undefined, type = undefined } = {},
  ) {
    const requestQuery = {
      page,
      limit,
      search,
      status,
      cryptoId,
      fiatId,
      direction,
      type,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/orders', params }).then(response => {
      commit('seOrders', { orders: response.data, params })

      return response?.data
    })
  },
};

const getters = {
  orders: store => store.orders,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};