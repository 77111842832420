export default [
  // transaction-monitor
  {
    path: '/currency-fiat',
    name: 'currency-fiat',
    component: () => import('@/views/apps/currency-fiat/CurrencyFiatView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'market-currency-page',
      pageTitle: 'Currency Fiat',
      breadcrumb: [
        {
          text: 'Currency Fiat',
        },
      ],
    },
  },

  {
    path: '/currency-fiat/edit/:id',
    name: 'currency-fiat-edit',
    component: () => import('@/views/apps/currency-fiat/CurrencyFiatEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'market-currency-page',
      pageTitle: 'Fiat Currency',
      breadcrumb: [
        {
          text: 'Edit',
        },
      ],
    },
  },
]
