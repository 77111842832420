import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  holdInfo: {},
  freeAccounts: {},
  holdUsers: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  levels: [],
})

const actions = {
  fetchHoldInfo({ commit }) {
    return axios({ method: 'GET', url: '/hold/settings' }).then(response => {
      commit('setHoldInfo', response.data)

      return response
    })
  },
  updateHoldInfo(store, form) {
    console.log('FORM 11111 - ', form)
    return axios({ method: 'POST', url: '/hold/settings', data: form })
  },
  getFreeAccounts({ commit }) {
    return axios({ method: 'GET', url: '/hold/data' }).then(response => {
      commit('setFreeAccounts', response.data)

      return response
    })
  },

  fetchListHoldUsers(
    { commit },
    { limit = 20, page = 1, levelIds = undefined, search = undefined, sortColumn = undefined, sortOrder = undefined } = {},
  ) {
    const requestQuery = {
      page,
      limit,
      levelIds,
      search,
      sortColumn,
      sortOrder,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/hold/users', params }).then(response => {
      commit('setHoldUsers', { items: response.data, params })

      return response?.data
    })
  },

  toggleKOL({ commit }, { id } = {}) {
    return axios({ method: 'POST', url: `/users/toggle-kol/${id}` }).then(response => {
      commit('updateUserItemInList', response.data)

      return response
    })
  },
  fetchHoldLevels({ commit }) {
    return axios({ method: 'GET', url: '/hold/levels' }).then(response => {
      commit('setHoldLevel', response.data)

      return response
    })
  },
  updateHoldLevels(store, { id, form }) {
    return axios({ method: 'POST', url: `/hold/levels/${id}`, data: form })
  },
}

const mutations = {
  setHoldInfo(state, payload) {
    state.holdInfo = payload
  },
  setFreeAccounts(state, payload) {
    state.freeAccounts = payload
  },
  setHoldUsers(state, payload) {
    state.holdUsers = payload
  },
  setHoldUsers(state, { items, params } = {}) {
    const { limit, page } = params

    state.holdUsers.value = items.items
    state.holdUsers.count = items.total
    state.holdUsers.limit = limit
    state.holdUsers.page = page
  },
  setHoldLevel(state, payload) {
    state.levels = payload
  },
}

const getters = {
  holdInfo: state => state.holdInfo,
  freeAccounts: state => state.freeAccounts,
  holdUsers: state => state.holdUsers,
  levels: state => state.levels,
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
