export default [
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/apps/faq/FAQView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'faq-page',
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
  {
    path: '/faq/create',
    name: 'faq-create',
    component: () => import('@/views/apps/faq/FAQCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'faq-page',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
          to: '/faq',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    path: '/faq/edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/apps/faq/FAQCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'faq-page',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
          to: '/faq',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
