export default [
  // transaction-monitor
  {
    path: '/buy-sell',
    name: 'buy-sell',
    component: () => import('@/views/apps/buy-sell/BuySellView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'buy-sell-page',
      pageTitle: 'Buy/Sell',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
  {
    path: '/buy-sell/:id',
    name: 'buy-sell',
    component: () => import('@/views/apps/buy-sell/BuySellDetail.vue'),
    meta: {
      requiredAuth: true,
      permission: 'buy-sell-page',
      pageTitle: 'Buy/Sell',
      breadcrumb: [
        {
          text: 'Detail',
        },
      ],
    },
  },
]
