export default [
  {
    path: '/newsletter',
    name: 'newsletter',
    component: () => import('@/views/apps/newsletter/NewsletterView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'emails-page',
      pageTitle: 'Email',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
]
