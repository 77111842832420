import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  journey: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  journey: store => store.journey,
}

const actions = {
  fetchJourney({ commit }, { limit = 30, page = 1, search = undefined } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/journey', params }).then(response => {
      commit('setJourney', { journey: response?.data, params })

      return response
    })
  },

  fetchCardById(store, id) {
    return axios({ method: 'GET', url: `/journey/${id}` }).then(response => response)
  },

  createCard(store, form) {
    return axios({
      method: 'POST',
      url: '/journey',
      data: form,
    })
  },

  updateCard(store, { id, form }) {
    return axios({
      method: 'PUT',
      url: `/journey/${id}`,
      data: form,
    })
  },

  removeCard({ commit }, id) {
    return axios({ method: 'DELETE', url: `/journey/${id}` }).then(response => {
      commit('removeCard', id)

      return response
    })
  },
}

const mutations = {
  setJourney(state, { journey, params } = {}) {
    const { limit, page } = params

    state.journey.value = journey.items || []
    state.journey.count = journey.total || 0
    state.journey.limit = limit
    state.journey.page = page
  },

  removeCard(state, id) {
    state.journey.value = state.journey.value?.filter(question => question.id !== id)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
