import axios from '@/plugins/axios'
import { transactionPages } from '@/views/apps/wallet-transactions/config/transactionTypes'
import { swapStatusTypes } from '@/views/apps/swap/config/swapTypes'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  transactions: {
    [transactionPages.wallet]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
    [transactionPages.swap]: {
      page: 1,
      value: [],
      count: 0,
      limit: 30,
    },
  },
  failedCounter: null,
})

const getters = {
  transactions: store => store.transactions,
  failedCounter: store => store.failedCounter,
}

const actions = {
  fetchTransactions(
    { commit },
    {
      page = 1,
      limit = 30,
      search = undefined,
      coinId = undefined,
      type = undefined,
      status = undefined,
      userAddress = undefined,
      userTelegramName = undefined,
    } = {},
  ) {
    const requestQuery = {
      page,
      limit,

      search,
      coinId,
      type,
      status,
      userAddress,
      userTelegramName,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/transactions/wallet', params }).then(response => {
      commit('setTransactions', { transactions: response.data, params, pageType: transactionPages.wallet })

      return response
    })
  },

  fetchSwap(
    { commit },
    { limit = 10, page = 1, userTelegramName = undefined, coinId = undefined, status = undefined } = {},
  ) {
    const requestQuery = {
      limit,
      page,
      userTelegramName,
      coinId,
      status,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/transactions/swap', params }).then(response => {
      commit('setTransactions', { transactions: response.data, params, pageType: transactionPages.swap })

      if (params.status === swapStatusTypes.failed) {
        commit('setFailedCounter', response.data?.total)
      }

      return response?.data
    })
  },

  fetchFailedSwapTransactions({ commit }) {
    return axios({
      method: 'GET',
      url: '/transactions/swap',
      params: { limit: 1, page: 1, status: swapStatusTypes.failed },
    }).then(response => {
      commit('setFailedCounter', response.data?.total)

      return response.data
    })
  },
}

const mutations = {
  setTransactions(state, { transactions, params, pageType = transactionPages.wallet } = {}) {
    const { limit, page } = params

    state.transactions[pageType].value = transactions.items || []
    state.transactions[pageType].count = transactions.total
    state.transactions[pageType].limit = limit
    state.transactions[pageType].page = page
  },

  setFailedCounter(state, total) {
    state.failedCounter = total
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
