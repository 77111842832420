export const transactionTypes = {
  withdrawal: 'withdrawal',
  deposit: 'deposit',
  transfer: 'transfer',
  deal: 'deal',
  referral: 'referral',
  cheque: 'cheque',
  withdrawal_nft: 'withdrawal_nft',
  deposit_nft: 'deposit_nft',
  farming: 'farming',
  clicker: 'clicker',
  bonus: 'bonus',
  daily_checkin: 'daily_checkin',
  task: 'task',
}

export const transactionTypesSelect = [
  {
    value: 'withdrawal',
    label: 'Withdrawal',
  },
  {
    value: 'deposit',
    label: 'Deposit',
  },
  {
    value: 'transfer',
    label: 'Transfer',
  },
  {
    value: 'deal',
    label: 'Deal',
  },
  {
    value: 'referral',
    label: 'Referral',
  },
  {
    value: 'cheque',
    label: 'Voucher',
  },
  {
    value: 'withdrawal_nft',
    label: 'Withdrawal NFT',
  },
  {
    value: 'deposit_nft',
    label: 'Deposit NFT',
  },
  {
    value: 'farming',
    label: 'Farming',
  },
  {
    value: 'clicker',
    label: 'Clicker',
  },
  {
    value: 'bonus',
    label: 'Bonus',
  },
  {
    value: 'daily_checkin',
    label: 'Daily checkin',
  },
  {
    value: 'task',
    label: 'Task',
  },
]

export const statusTypes = {
  success: 'success',
  pending: 'pending',
  reject: 'reject',
  error: 'error',
}

export const transactionPages = {
  wallet: 'wallet',
  swap: 'swap',
}

export const transactionStatus = {
  pending: 'pending',
  success: 'success',
}
