export default [
  {
    path: '/kyc-config',
    name: 'wallet-limits-kyc',
    component: () => import('@/views/apps/wallets-limits-kyc/WalletLimitsKycView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-config-page',
      pageTitle: 'Limits & KYC',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },

  {
    path: '/kyc-config/edit/:id',
    name: 'wallet-limits-kyc-edit',
    component: () => import('@/views/apps/wallets-limits-kyc/WalletLimitsKycEdit.vue'),
    meta: {
      requiredAuth: true,
      permission: 'kyc-config-page',
      pageTitle: 'Limits & KYC',
      breadcrumb: [
        {
          text: 'Edit',
        },
      ],
    },
  },
]
