import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  kycConfig: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  kycConfig: store => store.kycConfig,
}

const actions = {
  fetchKYCConfig({ commit }, { limit = 30, page = 1 } = {}) {
    const requestQuery = {
      limit,
      page,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/kyc-config', params }).then(response => {
      commit('setKYCConfig', { kycConfig: response?.data, params })

      return response
    })
  },

  async fetchKYCConfigById(store, id) {
    return axios({ method: 'GET', url: `/kyc-config/${id}` })
  },

  updateKYCConfig(store, { id, form }) {
    return axios({
      method: 'PUT',
      url: `/kyc-config/${id}`,
      data: form,
    })
  },
}

const mutations = {
  setKYCConfig(state, { kycConfig, params } = {}) {
    const { limit, page } = params

    state.kycConfig.value = kycConfig.items || []
    state.kycConfig.count = kycConfig.total || 0
    state.kycConfig.limit = limit
    state.kycConfig.page = page
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
