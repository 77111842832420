import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  cryptoCurrency: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  cryptoCurrency: store => store.cryptoCurrency,
}

const actions = {
  fetchCryptoCurrency({ commit }, { limit = 10, page = 1, search = '' } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/crypto-currency', params }).then(response => {
      commit('setCurrency', { cryptoCurrency: response.data, params })

      return response?.data
    })
  },

  fetchCryptoCurrencyById(store, id) {
    return axios({ method: 'GET', url: `/crypto-currency/${id}` })
  },

  updateCryptoCurrency(store, { id, form }) {
    return axios({ method: 'PATCH', url: `/crypto-currency/${id}`, data: form })
  },

  fetchSearchedCryptoCurrency({ dispatch }, search) {
    return dispatch('fetchCryptoCurrency', { search })
  },
}

const mutations = {
  setCurrency(state, { cryptoCurrency, params } = {}) {
    const { limit, page } = params

    state.cryptoCurrency.value = cryptoCurrency.items || []
    state.cryptoCurrency.count = cryptoCurrency.total || 0
    state.cryptoCurrency.limit = limit
    state.cryptoCurrency.page = page
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
