import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  newsletter: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
})

const getters = {
  newsletter: store => store.newsletter,
}

const actions = {
  fetchNewsletter({ commit }, { limit = 30, page = 1, search = undefined } = {}) {
    const requestQuery = {
      limit,
      page,
      search,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/newsletter', params }).then(response => {
      commit('setNewsletter', { newsletter: response?.data, params })

      return response
    })
  },

  fetchFile() {
    return axios({ method: 'GET', url: `/newsletter/download`, responseType: 'blob' })
  },
}

const mutations = {
  setNewsletter(state, { newsletter, params } = {}) {
    const { limit, page } = params

    state.newsletter.value = newsletter.items || []
    state.newsletter.count = newsletter.total || 0
    state.newsletter.limit = limit
    state.newsletter.page = page
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
