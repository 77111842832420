export default [
  {
    path: '/journey',
    name: 'journey',
    component: () => import('@/views/apps/journey/JourneyView.vue'),
    meta: {
      requiredAuth: true,
      permission: 'journey-page',
      pageTitle: 'Journey',
      breadcrumb: [
        {
          text: 'View',
        },
      ],
    },
  },
  {
    path: '/journey/create',
    name: 'journey-create',
    component: () => import('@/views/apps/journey/JourneyCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'journey-page',
      breadcrumb: [
        {
          text: 'Journey',
          active: true,
          to: '/journey',
        },
        {
          text: 'Create',
        },
      ],
    },
  },
  {
    path: '/journey/edit/:id',
    name: 'journey-edit',
    component: () => import('@/views/apps/journey/JourneyCreate.vue'),
    meta: {
      requiredAuth: true,
      permission: 'journey-page',
      breadcrumb: [
        {
          text: 'Journey',
          active: true,
          to: '/journey',
        },
        {
          text: 'Edit',
        },
      ],
    },
  },
]
