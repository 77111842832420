import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json',
  },
})
instance.interceptors.request.use(
  function(config) {
    // Do something before request is sent

    const token = Vue.$cookies.get('token')
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
      // rememberMe.refresh();
    }

    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  function(config) {
    return config
  },
  function(error) {
    // Do something with request error
    const code = error.response?.status

    switch (code) {
      case 400:
        console.log(error, '400')
        break
      case 403:
        console.log(error, '403')
        break
      case 401:
        store.dispatch('auth/logout')
        break
      case 405:
        console.log(error, 'Method Not Allowed')
        break
      case 409:
        console.log(error, 'Conflict.')
        break
      case 422:
        console.log(error, 'invalid credentials')
        break
      case 500:
      case 503:
        break
      default:
        console.log('no errors')
        break
    }

    return Promise.reject(error)
  },
)

export default instance
