import Vue from 'vue'
import store from '@/store'
import io from 'socket.io-client'

const SocketPlugin = {
  install(Vue) {
    const socket = io(process.env.VUE_APP_API_URL_WEBSOCKET, {
      extraHeaders: { Authorization: store.getters['auth/token'] },
    })
    socket.on('msgToClient', (message) => {
      // console.log('msgToClient', message)
      store.commit('deals/setDealUpdate', { date: Date.now(), deal: message.data })
    })
    const reconnect = () => {
      socket.disconnect()
      socket.io.opts.extraHeaders.Authorization = store.getters['auth/token']
      socket.connect()
    }

    Vue.prototype.$ws = { reconnect }

    setTimeout(() => {
      Vue.prototype.$ws.reconnect()
    }, 200)
  },
}
export default SocketPlugin