import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  invites: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  pendingCounter: null,
})

const getters = {
  invites: store => store.invites,
  pendingCounter: store => (store.pendingCounter < 0 ? 0 : store.pendingCounter),
}

const actions = {
  fetchUserInvites(
    { commit },
    {
      limit = 10,
      page = 1,
      currentLocationId = undefined,
      countryOfResidenceId = undefined,
      userType = undefined,
      status = undefined,
    } = {},
  ) {
    const requestQuery = {
      limit,
      page,

      currentLocationId,
      countryOfResidenceId,
      userType,
      status,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/user-invites', params }).then(response => {
      commit('setInvites', { invites: response.data, params })

      return response?.data
    })
  },

  updateUserStatus({ commit, state }, { id, status }) {
    return axios({ method: 'PATCH', url: `/user-invites/${id}`, data: { status } }).then(response => {
      commit('updateUserItemInList', { id, status })
      commit('setPendingCounter', state.pendingCounter - 1)

      return response
    })
  },

  fetchPendingInvites({ commit }) {
    return axios({ method: 'GET', url: '/user-invites', params: { limit: 1, page: 1, status: 'pending' } }).then(
      response => {
        commit('setPendingCounter', response.data?.total)

        return response.data
      },
    )
  },
}

const mutations = {
  setInvites(state, { invites, params } = {}) {
    const { limit, page } = params

    state.invites.value = invites.items || []
    state.invites.count = invites.total || 0
    state.invites.limit = limit
    state.invites.page = page
  },

  setPendingCounter(state, total) {
    state.pendingCounter = total
  },

  updateUserItemInList(state, { id, status }) {
    const listDataIndex = state.invites?.value?.findIndex(item => item?.waitingList.id === id)

    if (listDataIndex === -1) return

    const statusTypes = { approve: 'approved', decline: 'declined' }
    const currentStatus = statusTypes[status] || 'pending'

    const invite = state.invites.value[listDataIndex]
    const waitingList = { ...state.invites.value[listDataIndex].waitingList, status: currentStatus }

    state.invites.value[listDataIndex] = { ...invite, waitingList }
    state.invites.value.splice(state.invites.value?.length)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
