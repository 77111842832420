import Decimal from 'decimal.js-light'

Decimal.set({ precision: 64, toExpNeg: -16 })

const DecimalPlugin = {
  install(Vue) {
    Vue.prototype.$decimal = Decimal
  },
}

export default DecimalPlugin
