import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  walletsDeFi: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  walletsCeFi: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
});

const mutations = {
  setWalletsDeFi(state, { items, params } = {}) {
    const { limit, page } = params

    state.walletsDeFi.value = items.items
    state.walletsDeFi.count = items.total
    state.walletsDeFi.limit = limit
    state.walletsDeFi.page = page
  },
  setWalletsCeFi(state, { items, params } = {}) {
    const { limit, page } = params

    state.walletsCeFi.value = items.items
    state.walletsCeFi.count = items.total
    state.walletsCeFi.limit = limit
    state.walletsCeFi.page = page
  },
};

const actions = {
  fetchListWalletsDeFi(
    { commit },
    { limit = 10, page = 1, walletAddress = undefined, username = undefined, coinId = undefined } = {},
  ) {
    const requestQuery = {
      page,
      limit,
      walletAddress,
      username,
      coinId,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/wallets/defi', params }).then(response => {
      commit('setWalletsDeFi', { items: response.data, params })

      return response?.data
    })
  },
  fetchListWalletsCeFi(
    { commit },
    { limit = 10, page = 1, walletAddress = undefined, username = undefined, coinId = undefined } = {},
  ) {
    const requestQuery = {
      page,
      limit,
      walletAddress,
      username,
      coinId,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/wallets/cefi', params }).then(response => {
      commit('setWalletsCeFi', { items: response.data, params })

      return response?.data
    })
  },
};

const getters = {
  walletsDeFi: store => store.walletsDeFi,
  walletsCeFi: store => store.walletsCeFi,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};