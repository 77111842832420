import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'
import { transactionBuySellStatus } from '@/views/apps/buy-sell/config/transactionTypes'

const state = () => ({
  buySell: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  buySellFailedCounter: null,
})

const getters = {
  buySell: store => store.buySell,
  buySellFailedCounter: store => store.buySellFailedCounter,
}

const actions = {
  fetchBuySell(
    { commit },
    {
      coinId = undefined,
      limit = 10,
      page = 1,
      term = undefined,
      from = undefined,
      to = undefined,
      type = undefined,
      status = undefined,
    } = {},
  ) {
    const requestQuery = {
      limit,
      page,

      coinId,
      term,
      from,
      to,
      type,
      status,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/pg-transactions/list', params }).then(response => {
      commit('setBuySell', { buySell: response.data, params })

      return response?.data
    })
  },

  fetchBuySellById(store, { id }) {
    return axios({ method: 'GET', url: '/pg-transactions', params: { id } })
  },

  retryBuySellTransaction(store, { id }) {
    return axios({ method: 'PUT', url: 'pg-transactions/repay', data: { id } })
  },

  fetchFailedBuySell({ commit }) {
    return axios({
      method: 'GET',
      url: '/pg-transactions/list',
      params: { limit: 1, page: 1, status: transactionBuySellStatus.error },
    }).then(response => {
      commit('setFailedCounter', response.data?.pagination?.total)

      return response
    })
  },
}

const mutations = {
  setBuySell(state, { buySell, params } = {}) {
    const { limit, page } = params

    state.buySell.value = buySell.data
    state.buySell.count = buySell.pagination?.total
    state.buySell.limit = limit
    state.buySell.page = page
  },

  setFailedCounter(state, total) {
    state.buySellFailedCounter = total
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
